.App-mobile-body {
    padding: 10px;
    background-color: #282c34;
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

@font-face {
    font-family: 'ethnocentric';
    src: local('Ethnocentric'), url(./fonts/ethnocentric_rg-webfont.woff) format('woff'), 
    url(./fonts/ethnocentric_rg-webfont.woff2) format('woff2');
}

  
.text-mobile-standard {
    font-family: 'ethnocentric';
    white-space:pre-wrap;
    font-size: 3vw;
    fill: white;
}

.Planet-mobile-Name-Preview {
    font-family: 'ethnocentric';
    white-space:pre-wrap;
    font-size: 20px;
    fill: white;
}

.Planet-mobile-Name-Title {
    font-family: 'ethnocentric';
    white-space:pre-wrap;
    font-size: 32px;
    fill: white;
}

.text-mobile-mission-header {
    font-family: 'ethnocentric';
    white-space:pre-wrap;
    font-size: 5vw; 
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.Text-mobile-Welcome {
    font-family: 'ethnocentric';
    white-space:pre-wrap;
    font-size: 42px;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    color: rgb(6, 104, 6);
}

.text-mobile-button {
    font-family: 'ethnocentric';
    white-space:pre-wrap;
    font-size: 4vw; 
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    color: white;
}
