@import 'Mobile.css';

.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.hidden-menu {
  display: none !important;
}

.SubMenu-Container {
  height:100%;
  display:flex;
  align-items: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body {
  background-color: #282c34;
  height: 100vh;
  min-height: fit-content;
  min-width: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

@font-face {
  font-family: 'ethnocentric';
  src: local('Ethnocentric'), url(./fonts/ethnocentric_rg-webfont.woff) format('woff'), 
  url(./fonts/ethnocentric_rg-webfont.woff2) format('woff2');
}
.Planet-Name-Preview {
  font-family: 'ethnocentric';
  font-size: 20px;
  fill: white;
}

.text-container {
  
  position: relative;
}

.Planet-Name-Title {
  font-family: 'ethnocentric';
  white-space:pre-wrap;
  font-size: 32px;
  color: white;
}

.Text-Standard {
  white-space:pre-wrap;
  font-family: 'ethnocentric';
  font-size: 20px;
  fill: white;
  color: white;
}

.text-top {
  top: 20px;
  text-align: left;
  position: absolute;
}

.text-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.text-centered-vertical {
  position: absolute;
  text-align: left;
  top: 50%;
  transform: translate(0, -50%);
}

.Text-MissionHeader {
  font-size: 28px;
}

.Text-Welcome {
  font-family: 'ethnocentric';
  font-size: 42px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  color: rgb(6, 104, 6);
}

.Text-MenuButton {
  font-family: 'ethnocentric';
  white-space:pre-wrap;
  font-size: 32px;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  color: white;

}

.shadow-light {
  filter: drop-shadow(0 0 0.5rem black);
}

.shadow-medium {
  filter: drop-shadow(0 0 0.75rem black);
}

.MainMenu-body {
  position:absolute;
  top: calc(50% - 400px);
  left: calc(50% - 400px); 
  min-width:800px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.MainMenu-buttonfield {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  inline-size: fit-content;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrolbar for IE, Edge and Firefox */
.hideScroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
} 

.Archive-Menu {
  overflow: scroll;
  max-height:80vh;
}

.sector-menu {
  overflow: scroll;
  max-height:90vh;
}

.MainMenu-MoveAnimation {
  animation-name: "WelcomeScreen-MoveLogo";
  animation-duration:1s;
  animation-fill-mode: forwards;

  -moz-animation-name: "WelcomeScreen-MoveLogo";
  -moz-animation-duration:1s;
  -moz-animation-fill-mode: forwards;
  
  -webkit-animation-name: "WelcomeScreen-MoveLogo";
  -webkit-animation-duration:1s;
  -webkit-animation-fill-mode: forwards;
  
  -o-animation-name: "WelcomeScreen-MoveLogo";
  -o-animation-duration:1s;
  -o-animation-fill-mode: forwards;
}

.SubMenu-Background {
  filter: drop-shadow(0 0 1rem black);
  background-color: rgb(27, 32, 32);
  border: 1px solid rgba(240, 248, 255, 0.3);
  border-radius: 10px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.App-Title {
  font-family: 'ethnocentric';
  font-size: 42px;
  fill: white;
}

@keyframes WelcomeScreen-MoveLogo {
  from {
    left: calc(50% - 400px); 
    top: calc(50% - 400px);
    transform: scale(1,1);
  }
  to {
    top: -150px;
    left: -200px; 
    transform: scale(0.5,0.5);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
